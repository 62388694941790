(() => {
  const sups = document.querySelectorAll("sup");
  sups.forEach((sup) => {
    if(!sup.textContent.includes("ref")) return;

    const refIds = [];
    const refTargets = [];
    const orders = [];
    
    const myPopover = document.createElement('div');
    const moreSubs = getMoreSubs(sup);

    moreSubs.unshift(sup);
  
    moreSubs.forEach((moreSup, index) => {
      const refId = moreSup.textContent;
      const refTarget = document.getElementById(refId);
      const order = refTarget.dataset.order;

      refIds.push(refId);
      refTargets.push(refTarget);
      orders.push(order);

      const clonedNode = refTarget.cloneNode(true);
      clonedNode.removeAttribute('id');
      clonedNode.removeAttribute('class');
      clonedNode.removeAttribute('hidden');

      myPopover.append(clonedNode);

      if(index > 0){
        moreSup.remove();
      }

    });

    const popoverId = refIds.join("") + '_';

    myPopover.setAttribute('popover', 'auto');
    myPopover.setAttribute('id', popoverId);
    myPopover.className ='refpopover';

    document.querySelector('main').append(myPopover);
     
    sup.classList.add('refsup');
    sup.innerHTML = '<button type="button" class="trigger-refpopover" popovertarget="'+popoverId+'">'+orders+'</button>';

    sup.querySelector("button").addEventListener("click", (event) => {
      const currentPopover = document.getElementById(popoverId);
      currentPopover.style.display = "block";
      
      const rect = sup.getBoundingClientRect();
      const scrollLeft = document.documentElement.scrollLeft;
      const scrollTop = document.documentElement.scrollTop;

      currentPopover.style.left = rect.left + scrollLeft - (currentPopover.clientWidth / 2) + "px";
      currentPopover.style.top = rect.top + scrollTop  - (currentPopover.clientHeight + 12) + "px";
      currentPopover.style.removeProperty('display');

      if(!currentPopover.contains(currentPopover.querySelector('button'))){
        const closeButton = document.createElement('button');
        closeButton.type ='button';
        closeButton.setAttribute('popovertarget', currentPopover.id);
        closeButton.setAttribute('popovertargetaction', 'hide');

        const closeLabel = document.createElement('span');
        closeLabel.classList.add('sr-only');
        closeLabel.textContent = 'schließen';

        closeButton.append(closeLabel);

        currentPopover.prepend(closeButton);
      }
    });

    function getMoreSubs(elem) {
      const sibs = [];
      while (elem = elem.nextSibling) {
          if (elem.nodeType === 3) break; // text node
          if (elem.nodeName.toUpperCase() === 'SUP') {
            sibs.push(elem);
          }
      }
      return sibs;
    }
  });
})();